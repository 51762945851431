<template>
    <section id="settings-subscription">
        <h2 class="pb-2">{{ $t('subscription') }}</h2>
        <div>По вопросам оплат свяжитесь с администрацией школы</div>
    </section>
</template>

<script>
    import EventBus from '@/services/event-bus'

    export default {
        data() {
            return {
            }
        },
        mounted() {
            EventBus.$emit('breadcrumbs',[
                { to: '/settings', title: this.$t('settings') },
                { to: '/settings/subscription', title: this.$t('subscription') },
            ])
        },
    }
</script>


